<template>
  <div>
    <el-table :data="qutaForm.rqta_part_list" border @selection-change="handleSelectionPart" class="el-table-two">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="配件名称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'rqta_part_list.' + scope.$index + '.quta_part_name'">
            <el-input
              show-word-limit
              maxlength="30"
              v-model="qutaForm.rqta_part_list[scope.$index].quta_part_name"
              placeholder="暂无配件名称"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="配件规格">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'rqta_part_list.' + scope.$index + '.quta_part_spec'">
            <el-input
              v-model="qutaForm.rqta_part_list[scope.$index].quta_part_spec"
              maxlength="255"
              show-word-limit
              placeholder="暂无配件规格"
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="配件价格">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'rqta_part_list.' + scope.$index + '.quta_part_price'">
            <el-input
              @change="getTotalPrice"
              v-model="qutaForm.rqta_part_list[scope.$index].quta_part_price"
              @input="
                qutaForm.rqta_part_list[scope.$index].quta_part_price = helper.keepTNum1(
                  qutaForm.rqta_part_list[scope.$index].quta_part_price
                )
              "
              @blur="
                qutaForm.rqta_part_list[scope.$index].quta_part_price = helper.calcPrice(
                  qutaForm.rqta_part_list[scope.$index].quta_part_price,
                  4,
                  100000000
                )
              "
              maxlength="13"
              show-word-limit
              placeholder="暂无配件价格"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="配件重量">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'rqta_part_list.' + scope.$index + '.quta_part_weight'">
            <el-input
              v-model="qutaForm.rqta_part_list[scope.$index].quta_part_weight"
              @input="
                qutaForm.rqta_part_list[scope.$index].quta_part_weight = helper.keepTNum1(
                  qutaForm.rqta_part_list[scope.$index].quta_part_weight
                )
              "
              @blur="
                qutaForm.rqta_part_list[scope.$index].quta_part_weight = helper.calcPrice(
                  qutaForm.rqta_part_list[scope.$index].quta_part_weight,
                  4,
                  100000000
                )
              "
              maxlength="13"
              show-word-limit
              placeholder="暂无配件重量"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <div class="vd_price_toatl">
      <div class="vd_price">
        <h3><span>总计:</span></h3>
        <h2 class="vg_ml_8" style="color: #c70e0e">
          <span>{{ totalPrice }}</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
export default {
  name: 'QutaPartList',
  props: {
    qutaForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectionsList: [],
      mtrlTypeGroupOpt: [],
      flag: true,
      totalPrice: ''
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    //prcs清单选择框
    handleSelectionPart(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionPart', this.selectionsList);
    },
    getTotalPrice() {
      let num = new BigNumber(0);
      if (this.qutaForm.rqta_part_list) {
        this.qutaForm.rqta_part_list.forEach(item => {
          let num1 = new BigNumber(0);
          num1 = num1.plus(Number(item.quta_part_price) ? Number(item.quta_part_price) : 0);
          num = num.plus(num1);
        });
      }
      num = num.toNumber();
      this.totalPrice = this.helper.haveFour(num, 4);
      this.$emit('calcPart');
    }
  }
};
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl {
  margin-left: 5px;
}
.vd_wid100 {
  width: 100%;
}
.el-input-group__prepend {
  font-size: 16px;
  color: #000000;
}
.vd_price_toatl {
  /* display: flex;
  justify-content: space-between; */
  padding: 10px 20px;
}

.vd_price {
  display: flex;
  justify-content: flex-end;
}
</style>
