<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <!-- <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSmplList()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="委托单号:" prop="requ_no">
              <el-input size="small" v-model.trim="searchForm.requ_no" clearable placeholder="请填写委托单号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="样品编号:" prop="smpl_no">
              <el-input size="small" v-model.trim="searchForm.smpl_no" clearable placeholder="请填写样品编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="样品名称:" prop="smpl_name">
              <el-input size="small" v-model.trim="searchForm.smpl_name" clearable placeholder="请填写样品名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="单据状态:" prop="status">
              <selectStatus ref="selectStatus" @selectStatusRow="getStatusVal"></selectStatus>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="样品类型:" prop="smpl_type">
              <el-select v-model="searchForm.smpl_type" placeholder="请选择样品类型" size="small" clearable>
                <el-option v-for="item in smplType" :key="item.id" :label="item.label" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="所属部门:" prop="belo_dept_id">
              <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="14">
            <el-form-item label="实际交样时间:" prop="timeValue">
              <el-date-picker size="small" v-model="searchForm.timeValue" type="datetimerange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getSmplsNow()" class="vg_ml_16">查询
              </el-button>
              <el-button type="info" size="small" class="vd_export" @click="getDequsNowO()"><i
                  class="el-icon-refresh-right"></i> 刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form> -->
    </div>
    <el-row class="vd_mar15">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-col>
    </el-row>
    <el-row>
      <div><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="custList"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="物料编号" prop="mtrl_no" align="center" />
          <el-table-column label="物料类型" prop="mtrl_type" align="center" :formatter="typeFormatter" />
          <el-table-column label="物料名称" prop="mtrl_name" align="center" />
          <el-table-column label="物料规格" prop="mtrl_spec" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.mtrl_spec">{{ scope.row.mtrl_spec }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>

          <el-table-column label="用量" prop="smpl_mtrl_consume" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.smpl_mtrl_consume">{{ scope.row.smpl_mtrl_consume }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="损耗百分比" prop="smpl_mtrl_loss" align="center">
            <template slot-scope="scope">
              <!-- {{scope.row}} -->
              <span v-if="scope.row.smpl_mtrl_loss">{{ scope.row.smpl_mtrl_loss }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>

          <!-- <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" show-overflow-tooltip /> -->
          <el-table-column label="单据状态" align="center">
            <template slot-scope="scope">
              <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
                {{ helper.getStatusName(scope.row.status).name }}</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@/api/modules/smpl';
import pubPagination from '@/components/common/pubPagination';
// import selectStatus from "@/views/component/selectStatus";
// import selectSectionType from '@/views/component/selectSectionType'
import helper from '@assets/js/helper.js';
import { optnAPI } from '@/api/modules/optn';
import { stffAPI } from '@/api/modules/staff';

export default {
  name: 'DequImport',
  props: {
    qutaForm: {
      type: Object,
      required: true
    }
  },
  components: {
    pubPagination
    // selectStatus,
    // selectSectionType
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        requ_no: null,
        smpl_name: null,
        smpl_no: null,
        smpl_type: null,
        status: null,
        belo_dept_id: null, //打样部门
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      totalPage: 0,
      btn: {},
      loading: true,
      dequStffList: [],
      custList: [],
      multiSelection: [],
      currentPage: 1,
      attr: [],
      users: [],
      title: '提示：相同数据仅限导入一条',
      typeList: [
        { value: 0, label: '原面料' },
        { value: 1, label: '辅料' },
        { value: 2, label: '包材' },
        { value: 3, label: '加工面料' }
      ]
    };
  },
  created() {
    this.initData();
    this.getUsers();
    // this.getAttr()
  },
  filters: {
    formatDequPrice(row) {
      return helper.haveFour(row);
    },
    // 样品类型转换
    formatSmplType(row) {
      if (row === 1) {
        return '新款';
      } else if (row === 2) {
        return '老款';
      } else if (row === 3) {
        return '修改款';
      }
    }
  },
  methods: {
    initData() {
      this.getSmplList();
    },
    getSmplList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(smplAPI.getSmplMtrlBySmplId, {
        smpl_id: this.qutaForm.smpl_id,
        requ_no: this.searchForm.requ_no,
        smpl_no: this.searchForm.smpl_no,
        smpl_name: this.searchForm.smpl_name,
        // smpl_type: this.searchForm.smpl_type,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        status: this.searchForm.status,
        page_no: this.currentPage,
        belo_dept_id: this.searchForm.belo_dept_id,
        smpl_type: 0
      })
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getSmplsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.searchForm.page_no = 1;
      this.getSmplList();
    },
    // 刷新
    getDequsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.searchForm.page_no = 1;
      this.$refs.searchForm.resetFields();

      this.getSmplList();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.desi_id;
    },
    // 多选
    handleSelectionChange(val, w) {
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getSmplList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 取消选择
    cancel() {
      this.clear();
      // this.$emit("pldequChildChanel",false)
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childChange', JSON.parse(JSON.stringify(this.multiSelection)));
        this.$nextTick(() => {
          this.$refs.multiTable.clearSelection();
        });
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    getUsers() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.users = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    getAttr() {
      get(optnAPI.getOptnByPermId, {
        perm_id: 10006
      })
        .then(res => {
          if (res.data.code === 0) {
            this.attr = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getUser(status) {
      let str = '';
      this.users.forEach(item => {
        if (status === item.stff_id) {
          str = item.stff_name;
        }
      });
      return str;
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    //物料类型
    typeFormatter(row) {
      let str = '';
      this.typeList.forEach(item => {
        if (item.value === row.mtrl_type) {
          str = item.label;
        }
      });
      return str;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_mrt {
  margin-top: 20px;
}

.vd_mar15 {
  margin: 15px 0;
}

.vd_col_font {
  font-size: 16px;
}
</style>
