<template>
  <div>
    <table border="1px" width="600px" cellpadding="0" cellspacing="0" class="vd_table">
      <tr>
        <th class="vd_th"></th>
        <th class="vd_th">金额</th>
      </tr>
      <tr>
        <td class="vd_td_o">利润百分比</td>
        <td class="vd_td_t">
          <!-- <el-form-item label-width="0" :prop="'profitForm.quta_profit_rate'">
            <el-input maxlength="11" v-model="qutaForm.profitForm.quta_profit_rate" @input="qutaForm.profitForm.quta_profit_rate = helper.keepTNum1(qutaForm.profitForm.quta_profit_rate)" @blur ="qutaForm.profitForm.quta_profit_rate = helper.retain1(qutaForm.profitForm.quta_profit_rate,2,1000000)" show-word-limit placeholder="暂无利润百分比"></el-input>
          </el-form-item> -->
          <slot></slot>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o">样品利润金额</td>
        <td class="vd_td_t">
          <el-form-item label-width="0">
            <el-input
              maxlength="11"
              disabled
              v-model="qutaForm.profitForm.smplPrice"
              show-word-limit
              placeholder="暂无利润百分比"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o">含税价格</td>
        <td class="vd_td_t">
          <el-form-item label-width="0">
            <el-input
              maxlength="11"
              disabled
              v-model="qutaForm.profitForm.priceIndTax"
              show-word-limit
              placeholder="暂无含税价格"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o">毛利率</td>
        <td class="vd_td_t">
          <el-form-item label-width="0">
            <el-input
              maxlength="11"
              disabled
              v-model="qutaForm.profitForm.grossProMargin"
              show-word-limit
              placeholder="暂无毛利率"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
    </table>
    <div>
      <el-row class="vg_mt_16">
        <el-col :md="2" :offset="2">备注</el-col>
        <el-col :md="20">
          <el-form-item label-width="0">
            <el-input
              maxlength="255"
              type="textarea"
              :rows="2"
              v-model="qutaForm.quta_remark"
              show-word-limit
              placeholder="暂无备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QutaProfit',
  props: {
    qutaForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableList: [
        {
          id: 0,
          name: '利润百分比',
          price: ''
        },
        {
          id: 0,
          name: '样品利润金额=含税总金额x利润百分比',
          price: ''
        },
        {
          id: 0,
          name: '含税价格=含税总金额+样品利润金额',
          price: ''
        },
        {
          id: 0,
          name: '毛利率=含税价格/总成本',
          price: ''
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
table .vd_td_t {
  width: 300px;
  height: 30px;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: none;
  border-top: none;
}
.vd_td_t:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}
table .vd_td_o {
  width: 100px;
  padding-left: 5px;
  color: #606266;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: #e9e9e9 1px solid;
  border-top: none;
}
.vd_td_o:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}
table {
  // border: #f3f3f3 1px solid;
  border: none;
}
.vd_table {
  margin-top: 20px;
  margin-left: 30px;
}
.vd_th {
  text-align: left;
  height: 30px;
  border-top: #e9e9e9 1px solid;
  background-color: #f5f7fa;
  color: #909399;
  border: none;
}
</style>
